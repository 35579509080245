import styled from 'styled-components';
import { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, setHours, setMinutes } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import breakfasts from '../data/breakfasts';
import sundayDinners from '../data/Sunday';
import mondayDinners from '../data/Monday';
import tuesdayDinners from '../data/Tuesday';
import wednesdayDinners from '../data/Wednesday';
import thursdayDinners from '../data/Thursday';
import fridayDinners from '../data/Friday';
import saturdayDinners from '../data/Saturday';

const locales = {
    'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const HomePageContainer = styled.div`
  padding: 20px 100px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const Section = styled.div`
  margin: 20px 0px 40px;
  width: 100%;

  h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #80CC28;
    text-align: center;
  }
`;

const dinnerFiles = {
    sundayDinners,
    mondayDinners,
    tuesdayDinners,
    wednesdayDinners,
    thursdayDinners,
    fridayDinners,
    saturdayDinners,
};

const START_DATE = new Date(2024, 11, 22); // Reference start date for rotation

// Helper function for breakfast rotation
function getBreakfast(currentDate) {
    const daysElapsed = Math.floor((currentDate - START_DATE) / (24 * 60 * 60 * 1000));
    const rotationIndex = daysElapsed % breakfasts.length; // Rotate through breakfasts daily
    return { ...breakfasts[rotationIndex], category: 'breakfasts' }; // Include category
}

// Helper function for dinner rotation
function getDinner(currentDate, weekday) {
    const totalDinners = dinnerFiles[Object.keys(dinnerFiles)[weekday]].length; // Dinners for the day
    const weeksElapsed = Math.floor((currentDate - START_DATE) / (7 * 24 * 60 * 60 * 1000));
    const rotationIndex = (weeksElapsed + totalDinners) % totalDinners; // Rotate dinners
    const dinnerCategory = Object.keys(dinnerFiles)[weekday];
    const dinner = dinnerFiles[dinnerCategory][rotationIndex];
    return { ...dinner, category: dinnerCategory }; // Include category
}

// Generate events for the calendar
function generateEvents(startDate, days) {
    const events = [];
    for (let i = 0; i < days; i++) {
        const eventDate = new Date(startDate);
        eventDate.setDate(startDate.getDate() + i);
        const weekday = eventDate.getDay();

        // Add breakfast event
        const breakfast = getBreakfast(eventDate);
        events.push({
            title: breakfast.name,
            start: setMinutes(setHours(eventDate, 7), 0),
            end: setMinutes(setHours(eventDate, 8), 0),
            recipeId: breakfast.recipeId,
            category: breakfast.category,
        });

        // Add dinner event
        const dinner = getDinner(eventDate, weekday);
        events.push({
            title: dinner.name,
            start: setMinutes(setHours(eventDate, 18), 0),
            end: setMinutes(setHours(eventDate, 19), 0),
            recipeId: dinner.recipeId,
            category: dinner.category,
        });
    }
    return events;
}

function HomePage() {
    const [events] = useState(() => {
        const today = new Date();
        return generateEvents(today, 365 * 2); // Generate 2 years of events
    });

    const handleEventClick = (event) => {
        // Send category and recipeId as query parameters
        window.open(`/recipe?category=${event.category}&recipeId=${event.recipeId}`, '_blank');
    };

    return (
        <HomePageContainer>
            <Section>
                <h2>Meal Calendar</h2>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, width: '100%' }}
                    onSelectEvent={handleEventClick}
                />
            </Section>
        </HomePageContainer>
    );
}

export default HomePage;
