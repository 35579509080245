import React from 'react';
import styled from 'styled-components';
/*import DOMPurify from 'dompurify';*/

const PageContainer = styled.div`
    padding: 60px 80px;
   /* display: flex;*/
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff; 
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    overflow-y: auto;
    color: #666;
    height: calc(100vh - 60px); /* Subtract footer height if fixed */
    box-sizing: border-box; /* Ensure padding is included in height calculation */

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 30px 20px;
        margin-bottom:195px;
    }

  /*  margin-bottom: 50px; */// Added margin-bottom for spacing  whitespace
`;
//  padding: 40px 40px 100px; /* Add extra bottom padding */
//  max-width: 800px;
//  margin: 0 auto;
//  font-family: 'Arial', sans-serif;
//  color: #CCC;
//  /* overflow-y: auto;  Enable scrolling for overflowing content */
//  height: calc(100vh - 60px); /* Subtract footer height if fixed */
//  box-sizing: border-box; /* Ensure padding is included in height calculation */
//`;

//const AboutText = styled.div`
//    flex: 1;  // Take up half the space (if there are two elements)

//    h2 {
//        font-size: 32px;
//        margin-bottom: 20px;
//        color: #80CC28;

//         @media (max-width: 768px) {  // Center the text on mobile view
//            text-align: center;
//        }
//    }

//    p {
//        font-size: 18px;
//        line-height: 1.6;
//        color: #666;
//        text-align: left;  // By default, text is aligned to the left

//        @media (max-width: 768px) {  // Center the text on mobile view
//            text-align: center;
//        }
//    }
//    button {
//        margin-top: 20px;
//        padding: 10px 20px;
//        border: none;
//        border-radius: 5px;
//        background-color: #80CC28;
//        color: #ffffff;
//        cursor: pointer;
//        transition: background-color 0.3s;

//        &:hover {
//            background-color: #72b237;
//        }
//    }
//`;
//const StyledLink = styled.a`
//    margin-top: 20px;
//    display: inline-block;
//    padding: 10px 20px;
//    background-color: #80CC28;
//    color: #ffffff;
//    border: none;
//    border-radius: 5px;
//    text-align: center;
//    text-decoration: none; // to remove underline from anchor
//    cursor: pointer;
//    transition: background-color 0.3s ease;

//    &:hover {
//        background-color: #72b237;
//    }

//    // This media query will center the link on screens up to 768px wide (typical breakpoint for tablets and smaller devices).
//    @media (max-width: 768px) {
//        display: block; // make it block-level
//        margin-left: auto;
//        margin-right: auto;
//    }
//`;

const Title = styled.h1`
  font-size: 36px;
  color: #80CC28;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #80CC28;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  color: darkred;
`;

const OrderedList = styled.ol`
  padding-left: 20px;
  color: darkred;
`;

const RecipeComponent = ({ recipe }) => (
  <PageContainer>
    <Title>{recipe.name}</Title>
    <SectionTitle>Ingredients</SectionTitle>
    <List>
      {recipe.ingredients.map((ingredient, index) => (
        <li key={index}>{ingredient}</li>
      ))}
    </List>
    <SectionTitle>Instructions</SectionTitle>
    <OrderedList>
      {recipe.instructions.map((instruction, index) => (
        <li key={index}>{instruction}</li>
      ))}
    </OrderedList>
    {recipe.notes && (
      <>
        <SectionTitle>Notes</SectionTitle>
        <List>
          {recipe.notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </List>
      </>
    )}
    {recipe.brands && (
      <>
        <SectionTitle>Recommended Brands</SectionTitle>
        <List>
          {recipe.brands.map((brand, index) => (
            <li key={index}>{brand}</li>
          ))}
        </List>
      </>
    )}
    {recipe.products && (
      <>
        <SectionTitle>Products I Like</SectionTitle>
        <List>
          {recipe.products.map((product, index) => (
            <li key={index}>{product}</li>
          ))}
        </List>
      </>
    )}
    {recipe.subRecipe && (
      <>
        <SectionTitle>{recipe.subRecipe.name}</SectionTitle>
        <h3>Ingredients</h3>
        <List>
          {recipe.subRecipe.ingredients.map((ingredient, index) => (
              <li key={index}>{ingredient}</li>
          ))}
        </List>
        <h3>Instructions</h3>
        <OrderedList>
          {recipe.subRecipe.instructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
          ))}
        </OrderedList>
      </>
    )}
  </PageContainer>
);

export default RecipeComponent;
