const recipes = [
    {
        name: "Frittata or Omelets and Toast",
        recipeId: "frittata-or-omelets-and-toast",
        ingredients: [
            "3 large eggs or egg substitute",
            "1/4 cup diced vegetables (spinach, tomatoes, onions, mushrooms, etc.)",
            "1 tsp Earth Balance (or vegan butter)",
            "Salt and pepper to taste",
            "1 slice whole-grain toast",
        ],
        instructions: [
            "Whisk eggs with salt and pepper.",
            "Heat a nonstick skillet over medium heat, melt the butter, and sauté the vegetables.",
            "Pour in the eggs and cook until set. Fold or serve flat.",
            "Toast the bread and serve alongside.",
        ],
    },
    {
        name: "Breakfast Cookie with Fruit Casserole on the Side",
        recipeId: "breakfast-cookie-with-fruit-casserole",
        ingredients: [
            "1 cup creamy peanut butter (or other nut butter)",
            "1/4 cup honey",
            "1 teaspoon vanilla extract",
            "2 medium ripe bananas, mashed",
            "1/2 teaspoon salt",
            "1 teaspoon ground cinnamon",
            "2 1/4 cups quick oats",
            "1/2 cup dried cranberries or raisins",
            "2/3 cup chopped nuts (e.g., almonds, walnuts, or pistachios)",
            "1 cup strawberries, chopped (for fruit casserole)",
            "1 medium banana, sliced (for fruit casserole)",
            "1/2 cup strawberry banana yogurt (for fruit casserole)"
        ],
        instructions: [
            "Preheat the oven to 325°F. Line a baking sheet with parchment paper or a non-stick baking mat.",
            "In a large mixing bowl (or stand mixer with paddle attachment), combine the peanut butter, honey, vanilla extract, mashed bananas, salt, and cinnamon until smooth.",
            "Stir in the oats, dried cranberries (or raisins), and chopped nuts until fully combined.",
            "Scoop about 1/4-cup mounds of the dough onto the prepared baking sheet, flattening each cookie slightly (cookies will not spread).",
            "Bake for 14 to 16 minutes until golden brown but still soft. Allow the cookies to cool on the baking sheet for 5 minutes, then transfer to a wire rack to cool completely.",
            "In a separate bowl, combine the chopped strawberries, sliced banana, and strawberry banana yogurt. Mix gently to coat the fruit evenly.",
            "Serve the fruit casserole alongside the breakfast cookies for a balanced and satisfying meal."
        ],
        notes: [
            "For a vegan option, replace honey with maple syrup and use dairy-free yogurt for the fruit casserole.",
            "Customize the cookies with your favorite dried fruits or seeds.",
            "Prepare the fruit casserole up to a day in advance and store it in the refrigerator."
        ]
    },
    {
        name: "British Fry Up",
        recipeId: "british-fry-up",
        ingredients: [
            "1 cup Great Northern beans",
            "2 eggs (poached, fried, or scrambled)",
            "1 medium tomato, grilled",
            "1/2 cup mushrooms, sautéed",
            "2 slices whole-grain toast",
        ],
        instructions: [
            "Warm the beans, grill the tomato, and sauté the mushrooms.",
            "Cook eggs to your preference.",
            "Serve with toast and a side of Earl Grey tea.",
        ],
    },
    {
        name: "Buckwheat Pancakes with Blueberries and Almonds",
        recipeId: "buckwheat-pancakes-with-blueberries-and-almonds",
        ingredients: [
            "1 1/2 cups buckwheat flour",
            "1 tbsp baking powder",
            "1 mashed banana",
            "2 cups pea milk (Ripple®, Sproud®, or another brand)",
            "1 tbsp maple syrup",
            "1/2 cup sliced almonds",
            "1/2 cup frozen or fresh blueberries",
        ],
        instructions: [
            "In a large bowl, mix the buckwheat flour and baking powder.",
            "In a separate bowl, mash the banana and mix it with the pea milk and maple syrup.",
            "Gradually combine the wet ingredients with the dry ingredients, stirring until just mixed. Do not overmix.",
            "Heat a non-stick skillet or griddle over medium heat and lightly grease if needed.",
            "Pour 1/4 cup of batter per pancake onto the skillet. Sprinkle a few blueberries and sliced almonds on each pancake while cooking.",
            "Cook until bubbles form on the surface and edges look set, about 2-3 minutes. Flip and cook the other side until golden brown, about 1-2 minutes more.",
            "Serve pancakes warm with additional maple syrup, blueberries, and almonds on top if desired.",
        ],
        notes: [
            "Pea milk brands like Ripple® and Sproud® work well in this recipe for a creamy texture.",
            "Frozen blueberries are convenient and work just as well as fresh. No need to thaw them before adding to the batter.",
            "For a nut-free option, omit the almonds or substitute with seeds like pumpkin or sunflower seeds.",
        ],
        brands: [
            "Ripple® Pea Milk",
            "Sproud® Pea Milk",
            "Silk® Protein Milk (pea-based option)",
        ],
        toppings: [
            "Extra blueberries",
            "Sliced almonds",
            "Additional maple syrup",
        ],
    },
 {
        name: "Pesto Bennies",
        recipeId: "pesto-bennies",
        ingredients: [
            "1 whole-grain English muffin (e.g., Ezekiel English Muffins, Sprouted Grain®, or Dave's Killer Bread Rockin' Grains Organic English Muffins®)",
            "2 tbsp homemade pesto (recipe below) or high-quality store-bought pesto",
            "1 medium tomato, sliced",
            "2 poached eggs",
            "Optional: 1 tbsp grated Romano or Parmesan-Reggiano cheese"
        ],
        instructions: [
            "Toast the English muffin halves until golden brown.",
            "Spread 1 tablespoon of pesto (homemade or store-bought) on each toasted muffin half.",
            "Layer with tomato slices.",
            "Lightly spray silicone egg poaching cups with oil. Fill a pot with 2-3 inches of water and bring to a gentle boil. Place the silicone cups in the water, crack an egg into each cup, cover the pot, and let cook for 4-5 minutes for a runny yolk or longer for a firmer yolk.",
            "Remove poached eggs from the cups and place one on each muffin half.",
            "Optionally, sprinkle grated cheese on top before serving."
        ],
        notes: [
            "For a low-sodium option, use NuSalt® to taste instead of regular salt in the pesto.",
            "If you prefer store-bought pesto, choose a high-quality brand like Rana® or Barilla®. Adjust seasoning as needed.",
            "Poaching eggs in silicone cups ensures consistent results. These cups are reusable and easy to clean.",
            "This dish is perfect for brunch or a hearty breakfast."
        ],
        brands: [
            "NuSalt® (low-sodium salt alternative)",
            "Ezekiel English Muffins, Sprouted Grain®",
            "Dave's Killer Bread Rockin' Grains Organic English Muffins®"
        ],
        products: [
            "Silicone Egg Poachers",
            "Extra Virgin Olive Oil"
        ],
        subRecipe: {
            name: "Homemade Pesto",
            ingredients: [
                "2 cups fresh basil leaves, packed",
                "1 cup spinach",
                "3/4 cup freshly grated Romano or Parmesan-Reggiano cheese (optional)",
                "3/4 cup extra virgin olive oil",
                "1/4 cup pine nuts (or substitute chopped walnuts)",
                "1/4 cup pistachios",
                "3 garlic cloves, minced",
                "1 small shallot",
                "1/2 tsp salt (use NuSalt® for a lower sodium option)",
                "1/4 tsp freshly ground black pepper, more to taste"
            ],
            instructions: [
                "Combine basil, spinach, cheese (if using), pine nuts, pistachios, garlic, shallot, salt, and pepper in a food processor.",
                "Pulse until finely chopped.",
                "With the processor running, slowly drizzle in olive oil until the pesto reaches your desired consistency.",
                "Taste and adjust seasoning as needed. Store in an airtight container in the refrigerator for up to a week."
            ]
        }
    },   
    {
        name: "Yogurt Parfaits",
        recipeId: "yogurt-parfaits",
        ingredients: [
            "1/2 cup flavored Greek yogurt (e.g., vanilla or berry flavor)",
            "1/2 cup fruit-flavored regular yogurt (e.g., strawberry or peach)",
            "1/2 cup fresh or frozen berries (e.g., blueberries, raspberries, strawberries)",
            "1/3 cup high-quality granola (e.g., Kind®, Purely Elizabeth®, or Cascadian Farm®)",
            "2 tbsp sliced almonds (or substitute walnuts, pecans, or pistachios)"
        ],
        instructions: [
            "In a glass or bowl, layer the Greek yogurt as the bottom layer.",
            "Add the fruit-flavored regular yogurt as the second layer, spreading it evenly.",
            "Top with fresh or frozen berries of your choice.",
            "Sprinkle granola evenly over the berries.",
            "Finish with sliced almonds or your preferred nuts.",
            "Serve immediately or refrigerate for up to 4 hours for a chilled parfait."
        ],
        notes: [
            "For a lower-calorie option, choose low-fat or non-fat yogurt and a lighter granola.",
            "Frozen berries can be a cost-effective and convenient alternative to fresh berries.",
            "If you prefer a sweeter parfait, drizzle with a small amount of honey or agave syrup."
        ],
        brands: [
            "Yogurt: Chobani®, FAGE®, or Siggi’s®",
            "Granola: Kind®, Purely Elizabeth®, Cascadian Farm®"
        ]
    },
    {
        name: "Oatmeal with Fruit and Nuts",
        recipeId: "oatmeal-with-fruit-and-nuts",
        ingredients: [
            "1/2 cup rolled oats",
            "3/4 cup pea milk",
            "1/2 cup bananas",
            "1/4 cup walnuts",
            "2 tsp maple syrup",
        ],
        instructions: [
            "Combine oats and milk. Cook on the stovetop or microwave.",
            "Stir in sweetener, top with fruit and nuts, and serve.",
        ],
    },
    {
        name: "Hippie Hash with Cranberry Juice",
        recipeId: "hippie-hash-with-cranberry-juice",
        ingredients: [
            "1 cup hash browns",
            "1/2 bell pepper, sliced",
            "1/2 onion, diced",
            "4 broccoli florets, chopped",
            "2 eggs, over-easy",
            "2 tbsp feta cheese",
            "2 slices whole-grain toast",
        ],
        instructions: [
            "Cook hash browns until crispy.",
            "Sauté vegetables in a separate pan.",
            "Top hash browns with vegetables, eggs, and cheese.",
            "Serve with toast and cranberry juice.",
        ],
    },
    {
        name: "Breakfast Crepes",
        recipeId: "breakfast-crepes",
        ingredients: [
            "2 cups pea milk",
            "4 eggs",
            "1 1/2 cups whole-wheat flour",
            "3 tbsp melted vegan butter",
        ],
        instructions: [
            "Blend all ingredients into a batter.",
            "Pour batter into a nonstick pan and cook on each side.",
            "Fill with fruit, nutella, or vegetables.",
        ],
    },
   {
        name: "Breakfast Sandwich with a Banana",
        recipeId: "breakfast-sandwich-with-a-banana",
        ingredients: [
            "1 whole-grain bagel",
            "1 egg",
            "1/2 slice cheddar cheese",
            "1 vegetarian sausage patty",
            "1 banana",
        ],
        instructions: [
            "Cook the egg and sausage.",
            "Assemble the sandwich with cheese, egg, and sausage.",
            "Serve with a banana.",
        ],
    },
    {
        name: "Suzie’s Sunrise Wakeup Shake",
        recipeId: "suzies-sunrise-wakeup-shake",
        ingredients: [
            "2/3 cup vanilla yogurt",
            "1 ripe banana (frozen, if possible)",
            "1 tbsp flax seeds",
            "2 tbsp wheat germ",
            "1/4 cup orange juice concentrate",
            "2/3 cup pea milk",
            "dry meal replacement vanilla mix (like ½ serving of Ka’Chava OR 1 full serving of Vega One All -in -One Shake OR 1 full serving of Garden of Life Raw Organic Meal OR 1 / 3 serving of Huel Powder.May also use pea protein powder or reduced - fat peanut butter powder.Calories, etc., and taste will vary.",
        ],
        instructions: [
            "Blend all ingredients with ice.",
            "Adjust sweetness with stevia or sweetener.",
        ],
    },
    {
        name: "Huevos Rancheros",
        recipeId: "huevos-rancheros",
        ingredients: [
            "2 corn tortillas",
            "Plain-tasting oil (e.g., canola or vegetable oil) for frying",
            "1/4 tsp taco seasoning (or to taste)",
            "1/2 cup refried beans (optional: mix with green chilis)",
            "2 eggs (over-easy or cooked to preference)",
            "1/4 cup Cotija or Monterey Jack cheese (optional)",
            "1/2 avocado, sliced (optional)",
            "1/4 cup salsa, picante, or freshly diced tomatoes",
            "Lime wedges for squeezing"
        ],
        instructions: [
            "Heat oil in a skillet over medium heat. Fry the corn tortillas until golden and crispy on both sides.",
            "Sprinkle the fried tortillas lightly with taco seasoning. Place them on paper towels to soak up excess oil.",
            "Warm the refried beans in a small pan. If desired, mix in some green chilis for added flavor.",
            "Spread a layer of refried beans on each tortilla.",
            "Cook the eggs over-easy or to your preference. Place one egg on each tortilla.",
            "Top with optional Cotija or Monterey Jack cheese, avocado slices, and your choice of salsa, picante, or freshly diced tomatoes.",
            "Squeeze a little lime juice over the finished dish for added brightness.",
            "Serve immediately and enjoy."
        ],
        notes: [
            "For a spicier version, use hot salsa or add jalapeños as a topping.",
            "To make it vegan, use plant-based cheese or omit cheese and eggs entirely.",
            "Corn tortillas fry best when the oil is heated to about 350°F."
        ],
         },
    {
        name: "Whole Grain Waffles with Berry Compote",
        recipeId: "waffles-with-berry-compote",
        ingredients: [
            "1 cup Kodiak® waffle mix",
            "3/4 cup pea milk (e.g., Ripple®, Sproud®)",
            "1 cup frozen or fresh mixed berries (e.g., blueberries, raspberries, strawberries)",
            "1 tbsp maple syrup",
            "1/2 tsp lemon juice",
            "1/4 tsp vanilla extract (optional)"
        ],
        instructions: [
            "Preheat your waffle maker and prepare the waffle batter by mixing the Kodiak® waffle mix with pea milk as per package instructions.",
            "Cook the waffles in the preheated waffle maker until golden and crisp. Set aside.",
            "In a small saucepan over medium heat, combine the mixed berries, maple syrup, and lemon juice. Stir occasionally and simmer for 5-7 minutes until the berries soften and release their juices.",
            "For extra flavor, add the vanilla extract (optional) and stir well.",
            "Let the compote cool slightly before pouring it over the freshly made waffles.",
            "Serve immediately and enjoy."
        ],
        notes: [
            "Using frozen berries is convenient and works just as well as fresh berries for the compote.",
            "For added texture, leave some whole berries in the compote or mash them lightly with a fork.",
            "Adjust the sweetness of the compote by adding more or less maple syrup to taste."
        ],
        brands: [
            "Waffle Mix: Kodiak® Power Cakes",
            "Pea Milk: Ripple® or Sproud®"
        ]
    },
    {
        name: "Whole Wheat Biscuits with Mushrooms, Gravy, and Pineapple",
        recipeId: "whole-wheat-biscuits-with-mushrooms-gravy-and-pineapple",
        ingredients: [
            "2 cups whole-wheat flour",
            "4 tsp baking powder",
            "1/2 tsp salt",
            "4 tbsp vegan butter",
            "1 cup pea milk",
            "1/2 cup sautéed mushrooms",
            "1/2 cup vegan gravy",
            "1/4 cup grilled pineapple",
        ],
        instructions: [
            "Mix biscuit dough, bake at 450°F for 10-12 minutes.",
            "Serve biscuits topped with mushrooms, gravy, and pineapple on the side.",
        ],
    },
];

export default recipes;