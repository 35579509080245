const saturdayDinners = [
    {
        number: 1,
        name: "My Dad’s Navy Bean Soup",
        recipeId: "navy-bean-soup",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Charcuterie Board",
        recipeId: "charcuterie-board",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Chickpea Pasta Salad",
        recipeId: "chickpea-pasta-salad",
        ingredients: [
            "1 pound whole-grain pasta",
            "1 can chickpeas, drained and rinsed",
            "1/2 cup vegan mayonnaise",
            "2 tbsp salad seasoning (e.g., a healthier equivalent of Salad Supreme)",
            "1 small red onion, diced",
            "2 celery stalks, diced"
        ],
        instructions: [
            "Cook the pasta according to package instructions. Drain and let cool.",
            "In a large bowl, mix the cooked pasta, chickpeas, vegan mayonnaise, salad seasoning, onion, and celery.",
            "Refrigerate for at least an hour before serving to allow flavors to meld."
        ],
        notes: ["Make ahead for convenience."]
    },
    {
        number: 4,
        name: "Chili and Cornbread",
        recipeId: "chili-cornbread",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Split Pea Soup with Whole Grain Rolls",
        recipeId: "split-pea-soup-rolls",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Cobb Salad & Bruschetta",
        recipeId: "cobb-salad-bruschetta",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Barley Salad with Stuffed Tomatoes",
        recipeId: "barley-salad-stuffed-tomatoes",
        ingredients: [
            "2 cups cooked pearl barley",
            "1 cucumber, diced",
            "1 cup cherry tomatoes, halved",
            "1/4 cup red onion, finely diced",
            "1/4 cup fresh parsley, chopped",
            "3 tbsp olive oil",
            "2 tbsp red wine vinegar",
            "1 tsp dried oregano",
            "Salt and pepper to taste",
            "4 large tomatoes (for stuffing)"
        ],
        instructions: [
            "In a large bowl, mix the cooked barley, cucumber, cherry tomatoes, red onion, parsley, olive oil, red wine vinegar, oregano, salt, and pepper.",
            "Slice off the tops of the large tomatoes and scoop out the insides. Reserve the tops for presentation.",
            "Stuff the hollowed-out tomatoes with the barley salad mixture.",
            "Serve immediately or refrigerate until ready to eat."
        ],
        notes: ["A hearty pairing for dinner."]
    },
    {
        number: 8,
        name: "A Base, A Veg, A Protein, & A Sauce!",
        recipeId: "base-veg-protein-sauce",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Potato Soup with Chopped Salad",
        recipeId: "potato-soup-chopped-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Warm Grain Bowl",
        recipeId: "warm-grain-bowl",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Fondue Dinner",
        recipeId: "fondue-dinner",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Tex-Mex Bean Bake with Cornbread Topping",
        recipeId: "tex-mex-bean-bake-cornbread",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default saturdayDinners;
