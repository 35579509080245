const thursdayDinners = [
    {
        number: 1,
        name: "Polenta or Barley and Roasted Veggies",
        recipeId: "polenta-barley-roasted-veggies",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Spaghetti with Lentilballs and Side Italian Salad",
        recipeId: "spaghetti-lentilballs-italian-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Angel Hair Pesto with Tomatoes on the Side",
        recipeId: "angel-hair-pesto-tomatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "French Onion Soup with Roasted Vegetable Salad",
        recipeId: "french-onion-soup-vegetable-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Pizza",
        recipeId: "pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Lasagna",
        recipeId: "lasagna",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "TVP Bolognese",
        recipeId: "tvp-bolognese",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "French Lentils with Provençal New Potatoes",
        recipeId: "french-lentils-provencal-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Butternut Squash Pitta Pizza",
        recipeId: "butternut-squash-pitta-pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Mushroom Risotto",
        recipeId: "mushroom-risotto",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Vegan Schnitzel with Spargle and Potatoes",
        recipeId: "vegan-schnitzel-spargle-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Tuscan White Bean Stew",
        recipeId: "tuscan-white-bean-stew",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default thursdayDinners;
