const fridayDinners = [
    {
        number: 1,
        name: "Urad Dal with Curried Cauliflower",
        recipeId: "urad-dal-curried-cauliflower",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Thai Green Curry with Spring Vegetables",
        recipeId: "thai-green-curry-spring-vegetables",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Vegetable Biryani",
        recipeId: "vegetable-biryani",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "General Tso’s Tofu",
        recipeId: "general-tsos-tofu",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Bibimbap",
        recipeId: "bibimbap",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Hot Pot",
        recipeId: "hot-pot",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Loaded Vegetable Ramen with Fried Wontons",
        recipeId: "loaded-vegetable-ramen-fried-wontons",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Kung Pao Vegetables with Peanuts",
        recipeId: "kung-pao-vegetables-peanuts",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Peanut Sauce Stir Fry",
        recipeId: "peanut-sauce-stir-fry",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Pad Pak",
        recipeId: "pad-pak",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Chickpea Masala",
        recipeId: "chickpea-masala",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Chinese Fried Rice and Eggrolls",
        recipeId: "chinese-fried-rice-eggrolls",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default fridayDinners;
