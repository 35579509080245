import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import recipes from '../../data/breakfasts'; // Ensure this path matches your structure
import RecipeComponent from '../../components/recipe'; // Ensure this path matches your structure

const PageContainer = styled.div`
 padding: 20px 20px 20px; 
/*  max-width: 800px;*/
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  color: #CCC;
  overflow-y: auto; /* Enable scrolling for overflowing content */
  height: calc(100vh - 60px); /* Subtract footer height if fixed */
  box-sizing: border-box; /* Ensure padding is included in height calculation */
`;

const Title = styled.h1`
  font-size: 36px;
  color: #80CC28;
  margin-bottom: 20px;
`;

function RecipePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeSlug = queryParams.get('recipeId'); // Use 'recipeId' from query string

  const recipe = recipes.find((r) => r.recipeId === recipeSlug);

  if (!recipe) {
    return (
      <PageContainer>
        <Title>Recipe Not Found</Title>
        <p>Please select a valid recipe from the menu.</p>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {/* Pass recipe data as a prop to RecipeComponent */}
      <RecipeComponent recipe={recipe} />
    </PageContainer>
  );
}

export default RecipePage;
