const mondayDinners = [
    {
        number: 1,
        name: "Bánh Mìs",
        recipeId: "banh-mis",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Chickpea 'Tuna'",
        recipeId: "chickpea-tuna",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Sausage and Peppers (Philly on Calendar)",
        recipeId: "sausage-and-peppers",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Burgers and Potato Salad",
        recipeId: "burgers-and-potato-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Sloppy Joes and Sweet Potato Fries",
        recipeId: "sloppy-joes-and-sweet-potato-fries",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Chicken Fried Tofu with Veggies and Dip",
        recipeId: "chicken-fried-tofu",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Subs or Wraps with Marinated Vegetable Salad",
        recipeId: "subs-or-wraps",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Hot Dogs, Baked Beans, and Corn",
        recipeId: "hot-dogs-baked-beans-corn",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Greek Hummus Wraps or Pita Pockets (on Calendar)",
        recipeId: "greek-hummus-wraps",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "BLT’s",
        recipeId: "blts",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Pulled King Oyster Mushrooms and Slaw Sandwiches",
        recipeId: "pulled-king-oyster-mushrooms",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Vegetarian Ruben",
        recipeId: "vegetarian-ruben",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default mondayDinners;
