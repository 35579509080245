const wednesdayDinners = [
    {
        number: 1,
        name: "Kabobs",
        recipeId: "kabobs",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 2,
        name: "Fasolakia Lathera and White Beans",
        recipeId: "fasolakia-lathera-white-beans",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Red Lentil Soup and Veggie Tartine",
        recipeId: "red-lentil-soup-veggie-tartine",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "Lemon Orzo Soup",
        recipeId: "lemon-orzo-soup",
        ingredients: [
            "6 cups no-chicken broth",
            "2 small onions, diced",
            "2 medium carrots, diced",
            "1 celery stalk, diced",
            "6 tbsp freshly squeezed lemon juice",
            "2 tbsp olive oil",
            "15 oz. cup white beans, drained and rinsed",
            "6 eggs, beaten",
            "2 cups Ripple (pea milk)",
            "1 cup whole-grain rice or whole-wheat orzo",
            "1 tsp salt, or to taste",
            "2 tsp pepper, or to taste"
        ],
        instructions: [
            "In a large pot, heat the olive oil over medium heat. Add the diced onions, carrots, and celery. Sauté until softened, about 5 minutes.",
            "Add the no-chicken broth and bring to a boil. Stir in the whole-grain rice or orzo, reduce heat to a simmer, and cook until tender, about 15-20 minutes.",
            "In a medium bowl, whisk together the eggs, Ripple, and lemon juice until well combined.",
            "Slowly ladle a cup of hot broth into the egg mixture while whisking constantly to temper the eggs. Repeat with another cup of broth.",
            "Gradually pour the tempered egg mixture back into the soup, stirring constantly, to thicken the soup without curdling the eggs.",
            "Add the white beans and cook for an additional 5 minutes to heat through.",
            "Season with salt and pepper to taste.",
            "Serve warm, garnished with lots of fresh chopped dill or parsley, lemon wedges, and optional crumbled feta."
        ],
        notes: [
            "For a vegan version, substitute eggs with a vegan egg replacer and use a plant-based feta alternative.",
            "This soup is great for meal prep and tastes even better the next day."
        ],
        optionalGarnishes: [
            "Lots of fresh chopped dill or parsley",
            "Fresh lemon wedges",
            "Feta (optional)"
        ]
    },
    {
        number: 5,
        name: "Susan’s Shawarma",
        recipeId: "susans-shawarma",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Dal Makhani",
        recipeId: "dal-makhani",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "Shakshuka",
        recipeId: "shakshuka",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "Falafel, Garlicky Hummus & Mediterranean Salad",
        recipeId: "falafel-hummus-mediterranean-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Buffalo Seitan, Freekah, and Salad",
        recipeId: "buffalo-seitan-freekah-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Spanakotashen and Black Lentil Salad",
        recipeId: "spanakotashen-black-lentil-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Ful",
        recipeId: "ful",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Mujaddara",
        recipeId: "mujaddara",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default wednesdayDinners;
