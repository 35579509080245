import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
//import Services from './components/Services';
//import Clients from './components/Clients';
import Nav from './components/Nav';
import About from './components/About';
import HomePage from './components/Homepage';
import GreenStatement from './components/Green';
import Five from './components/54321';
import RecipePage from './pages/recipes';

const PageWrapper = styled.div`
background: linear-gradient(135deg, #6d5bba, #48a9a6);
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  //flex: 1;
  //display: flex;
  flex-direction: column;
  align-items: center;
/*  overflow: hidden;*/
  color: #80CC28;
  padding-bottom: 80px; /* Add padding equal to or greater than the footer height */
`;

const Header = styled.header`
  background: linear-gradient(to bottom, white 80%, #5E2590 100%);
  padding: 20px 20px;
  display: flex;
  align-items: center;
  color: #80CC28;

  img {
    width: 260px;
    margin-right: 20px;
  }

  h1 {
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 15px 10px;

    img {
      width: 200px;
    margin: 0px 50px
    }
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin: 0 10px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    a {
      margin: 5px 0;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: linear-gradient(to top, white 80%, #5E2590 100%);
  padding: 20px 10px;
  color: #5E2590;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;

  a {
    color: #5E2590;
    padding: 0px 16px;
    text-decoration: none;
  }
  p {
    color: #5E2590;
    padding: 0px 16px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

function App() {
    const currentYear = new Date().getFullYear();
    return (
        <Router>
            <PageWrapper>
                <Header>
                    <Nav />
                </Header>
                <Container>
                    <Routes>
                        {/*<Route path="/services" element={<Services />} />*/}
                        {/*<Route path="/clients" element={<Clients />} />*/}
                        <Route path="/about" element={<About />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/environment" element={<GreenStatement />} />
                        <Route path="/54321" element={<Five />} />
                        <Route path="/recipe" element={<RecipePage />} />
                    </Routes>
                </Container>
                <Footer>
                    <FooterLinks>
                        {/*<Link to="/services">Services</Link>*/}
                        {/*<Link to="/clients">Clients</Link>*/}
                        <p>&copy; {currentYear} EAT 54321, Inc.</p>
                        <Link to="/about">About Us</Link>
                        <Link to="/environment">Environmental Commitment</Link>
                    </FooterLinks>
                </Footer>
            </PageWrapper>
        </Router>
    );
}

export default App;
