import styled from 'styled-components';
import { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import recipes from '../data/breakfasts'; // Ensure this points to your recipes data file

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const HomePageContainer = styled.div`
  padding: 20px 100px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const Section = styled.div`
  margin: 20px 0px 40px;
  width: 100%;

  h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #80CC28;
    text-align: center;
  }
`;

function HomePage() {
    // eslint-disable-next-line no-unused-vars
  const [events, setEvents] = useState(
    recipes.map((recipe, index) => ({
      title: recipe.name,
      start: new Date(2025, 0, 19 + index), // Example: Starting from Jan 19, 2025
      end: new Date(2025, 0, 19 + index),   // Same day for simplicity
      recipeId: recipe.recipeId,
    }))
  );

  const handleEventClick = (event) => {
    window.open(`/recipe?recipeId=${event.recipeId}`, '_blank');
  };

  return (
    <HomePageContainer>
      <Section>
        <h2>Breakfast Calendar</h2>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, width: '100%' }}
          onSelectEvent={handleEventClick}
        />
      </Section>
    </HomePageContainer>
  );
}

export default HomePage;
