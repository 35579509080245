import styled from 'styled-components';
import MyPhoto from './AnspaughHeadshot2.png';  // Import your photo

const AboutContainer = styled.div`
    padding: 60px 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff; 
    overflow-y: auto;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 30px 20px;
        margin-bottom:195px;
    }

  /*  margin-bottom: 50px; */// Added margin-bottom for spacing  whitespace
`;

const AboutText = styled.div`
    flex: 1;  // Take up half the space (if there are two elements)

    h2 {
        font-size: 32px;
        margin-bottom: 20px;
        color: #80CC28;

         @media (max-width: 768px) {  // Center the text on mobile view
            text-align: center;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        color: #666;
        text-align: left;  // By default, text is aligned to the left

        @media (max-width: 768px) {  // Center the text on mobile view
            text-align: center;
        }
    }
    button {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #80CC28;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #72b237;
        }
    }
`;
const StyledLink = styled.a`
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #80CC28;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none; // to remove underline from anchor
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #72b237;
    }

    // This media query will center the link on screens up to 768px wide (typical breakpoint for tablets and smaller devices).
    @media (max-width: 768px) {
        display: block; // make it block-level
        margin-left: auto;
        margin-right: auto;
    }
`;

const AboutImage = styled.img`
    flex: 1;  // Take up half the space
    max-width: 30%;
    border-radius: 8px; // Optional, to give the image rounded corners

    @media (max-width: 768px) {  // Responsive adjustment for smaller screens
        margin-top: 30px;
        max-width: 80%;  // Make the image a bit larger on mobile, so it takes more space
    }
`;

function About() {
    return (
        <AboutContainer>  
            <AboutText>
                <h2>About the Founder</h2>  {/* Updated the title */}
                <p>
                    Hi, I&rsquo;m Susan M. Anspaugh, the creator of EAT 54321. My weight peaked at 324 pounds, where I could barely walk, and I&rsquo;m committed to reaching 145 pounds&mdash;with 135 as a dream goal. Honesty is my foundation. I&rsquo;ll never claim to have done the work if it's not true&mdash;no large liposuction or bariatric surgery, and no misleading before&mdash;and&mdash;after photos. I believe in transparency, whether it&rsquo;s about my dyed hair, a little filler, or the real work behind my weight&mdash;loss journey.
                    </p>
                    <p>
                    EAT 54321 has been my personal obsession for over 20 years, born out of the chaos of being a working, divorced mom trying to make healthy meals while navigating the conflicting (and often false) information about nutrition. Fueled by my autism, I&rsquo;ve spent years diving into data to create a system that&rsquo;s practical, reliable, and empowering.
                </p>
                <p>
                    This isn&rsquo;t just a program&mdash;it&rsquo;s my mission to help people fuel their bodies and change their worlds. Follow my journey, demand honesty, and discover how EAT 54321 can work for you.
                </p>
                <StyledLink
                    href="/contact"
                    alt="contact us"
                >
                    Subscribe for free
                </StyledLink>
                </AboutText>
            <AboutImage src={MyPhoto} alt="Susan M. Anspaugh" />
           </AboutContainer>
    );
}

export default About;
